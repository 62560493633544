import React from 'react'
import jobmanage from "../Assets/Images/jcm.webp"
import customercare from "../Assets/Images/cvm.webp"
import serviceparts from "../Assets/Images/Sparts.webp"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import flash from "../Assets/Images/flashcircle.svg"
import piechart from "../Assets/Images/piechart.svg"
import history from "../Assets/Images/history.svg"
import accurate from "../Assets/Images/accurate.svg"
import btnarrow from "../Assets/Images/w-btnarrow.svg"
import catalouge from "../Assets/Images/catalouge.svg"
import wheel from "../Assets/Images/Wheel.svg"
import status from "../Assets/Images/status.svg"
import notes from "../Assets/Images/notes.svg"
import city from "../Assets/Images/city.svg"
import identity from "../Assets/Images/identity.svg"
import bus from "../Assets/Images/Bus.svg"
import icongraph from "../Assets/Images/icongraph.svg"


const HomeSection5 = ({ textColor }) => {
    return (
        <div className='h-section-5 w-100 flex justify-center items-center bg-[#EEF2F3] ' >
            <div className='h-sec5-banner lg:w-[80%] w-[100%]    pb-[40px] px-[0] pt-[56px] '>
                <div className='flex flex-column justify-center items-center gap-[60px]'>
                    <div className='row g-4 '>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2 '>
                            <div className='b-left-5 '>
                                <LazyLoadImage src={jobmanage} alt='work' className='work-img w-100 h-100' />
                            </div>
                            <div className='b-right-5 h-100 flex flex-col justify-start items-start gap-[8px] mt-[30px] desktop-hide '>
                                <div className='col-lg-10 col-md-10 col-12  mt-[16px] desktop-hide'>
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={flash} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Instant Job Card <br />Generation</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={piechart} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Real-time<br />Job Tracking</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2 ' style={{ marginTop: "12px" }}>
                                        <div className='col-lg-6 col-md-6 col-6 '>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={history} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Comprehensive <br />Service History</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={accurate} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Accurate Estimates <br />& Invoicing</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[8px] px-[24px] flex justify-between items-center gap-[10px] rounded-[40px] text-[16px] font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[20px] desktop-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1 '>
                            <div className='b-right-5 h-100 flex flex-col justify-center items-start '>
                                <div className='job-card-div '>
                                    <p className='mb-0 font-bold text-[#537E30]'>JOB CARD MANAGEMENT</p>
                                </div>
                                <h2 className='h-text-1 text-[30px] font-semibold mb-0 text-[#173300] mt-[8px] '>Optimize Your Garage <br className='break' />
                                    Operations With Efficient Job
                                    Card
                                </h2>
                                <p className='p-text-1  font-normal text-[#173300] mb-0  mt-[20px]' style={{ lineHeight: "156%" }}>Auto Nexxus GMS empowers your garage with robust job card management tools. Streamline your workflow, improve efficiency, and enhance customer satisfaction with our advanced features.</p>
                                <div className='col-lg-10 col-md-10 col-12  mt-[32px] '>
                                    <div className='row g-2 tab-hide mobile-hide '>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={flash} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Instant Job Card <br />Generation</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={piechart} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Real-time<br />Job Tracking</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2 tab-hide mobile-hide card-hsec-5-m' >
                                        <div className='col-lg-6 col-md-6 col-6 '>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={history} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Comprehensive <br />Service History</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={accurate} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Accurate Estimates <br />& Invoicing</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[8px] px-[24px] flex justify-between items-center gap-[10px] rounded-[40px] text-[16px] font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[32px] tab-hide mobile-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row g-4 '>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-2 '>
                            <div className='b-left-5 '>
                                <LazyLoadImage src={customercare} alt='work' className='work-img w-100 h-auto' />
                            </div>
                            <div className='b-right-5 h-100 flex flex-col justify-start items-start gap-[8px] mt-[30px] desktop-hide '>
                                <div className='col-lg-10 col-md-10 col-12 desktop-hide mt-[16px]' >
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-6 col-6 '>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={catalouge} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Comprehensive<br />Service Catalouge</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={wheel} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Real-Time Parts<br />Inventory Tracking</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2 ' style={{ marginTop: "12px" }}>
                                        <div className='col-lg-6 col-md-6 col-6 p-0'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={status} alt='flash' className='c' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Service Status <br />Tracking</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6 p-0'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={notes} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Detailed Service & <br />Parts Reporting</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[6px] px-[15px] flex justify-between items-center gap-[10px] rounded-[40px] text-[16px] font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[20px] desktop-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-1'>
                            <div className='b-right-5 h-100 flex flex-col justify-center  items-start  '>
                                <div className='job-card-div  '>
                                    <p className='mb-0 font-bold text-[#537E30] '>SERVICE & PARTS MANAGEMENT</p>
                                </div>
                                <h2 className='h-text-1 text-[30px] font-semibold text-[#173300] mb-0 mt-[8px]'>Streamline Your Garage Services <br className='break' />
                                    With Smart Parts Management
                                </h2>
                                <p className='p-text-1 font-normal text-[#173300] mb-0  mt-[20px]' style={{ lineHeight: "156%" }}>Auto Nexxus GMS enhances your garage operations with powerful service and parts management tools. Optimize inventory, track usage, and deliver consistent, high-quality service to your customers.</p>
                                <div className='col-lg-10 col-md-10 col-12 tab-hide mobile-hide mt-[32px] ' >
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-6 col-6 '>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={catalouge} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Comprehensive<br />Service Catalouge</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={wheel} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Real-Time Parts<br />Inventory Tracking</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2 tab-hide mobile-hide card-hsec-5-m'>
                                        <div className='col-lg-6 col-md-6 col-6 p-0'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={status} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Service Status <br />Tracking</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6 p-0'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={notes} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Detailed Service & <br />Parts Reporting</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[8px] px-[24px] flex justify-between items-center gap-[10px] rounded-[40px] text-[16px] font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[32px] tab-hide mobile-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row g-4'>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2 '>
                            <div className='b-left-5 '>
                                <LazyLoadImage src={serviceparts} alt='work' className='work-img w-100 h-auto' />
                            </div>
                            <div className='b-right-5 h-100 flex flex-col justify-start items-start gap-[8px] mt-[30px]  desktop-hide'>
                                <div className='col-lg-10 col-md-10 col-12 mt-[16px] desktop-hide'>
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={city} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Multi-Garage Customer<br />Management</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={identity} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Centralized Customer<br />Information</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2' style={{ marginTop: "12px" }}>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={bus} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Vehicle<br />History Tracking</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={icongraph} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Detailed Customer & <br />Vehicle Reporting</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[6px] px-[15px] flex justify-between items-center gap-[10px] rounded-[40px]  font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[20px]  tab-hide mobile-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                    <LazyLoadImage src={btnarrow} alt='arrow' />
                                </button>
                                <button className='btn-banner-join py-[6px] px-[15px] flex justify-between items-center gap-[10px] rounded-[40px]  font-semibold text-[#1A2B1A] border-[2px] border-solid border-[#1A2B1A]' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1'>
                            <div className='b-right-5 h-100 flex flex-col justify-center items-start lg:gap-[10px] '>
                                <div className='job-card-div  '>
                                    <p className='mb-0  font-bold text-[#537E30]'>CUSTOMER & VEHICLE MANAGEMENT</p>
                                </div>
                                <h2 className='h-text-1 text-[30px] font-semibold text-[#173300] mb-0 mt-[8px]'>Elevate Customer Experience <br className='break' />
                                    With Seamless Management
                                </h2>
                                <p className='p-text-1  font-normal text-[#173300] mb-0 mt-[20px]' style={{ lineHeight: "156%" }}>Auto Nexxus GMS simplifies the management of customer and vehicle data. Build stronger relationships, streamline operations, and deliver personalized service with our intuitive tools.</p>
                                <div className='col-lg-10 col-md-10 col-12 mt-[16px] tab-hide mobile-hide'>
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[12px] border-0 bg-transparent'>
                                                <LazyLoadImage src={city} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Multi-Garage Customer<br />Management</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={identity} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Centralized Customer<br />Information</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-2' style={{ marginTop: "12px" }}>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={bus} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2 font-semibold text-[#454A45]'>Vehicle<br />History Tracking</h5>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-6'>
                                            <div className='card flex flex-col justify-start items-start gap-[10px] border-0 bg-transparent'>
                                                <LazyLoadImage src={icongraph} alt='flash' className='card-img-hs5' />
                                                <h5 className='h-text-2  font-semibold text-[#454A45]'>Detailed Customer & <br />Vehicle Reporting</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='btn-banner-join py-[8px] px-[24px] flex justify-between items-center gap-[10px] rounded-[40px]  font-semibold text-[#1A2B1A] border-[1.5px] border-solid border-[#1A2B1A] mt-[20px]  tab-hide mobile-hide' data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default HomeSection5
