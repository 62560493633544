import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import btnarrow from "../Assets/Images/r-arrow-round.svg";




const CustomCursor = ({ showCursor }) => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    if (!showCursor) return null;

    return (
        <div
            className="custom-cursor"
            style={{
                left: `${cursorPosition.x}px`,
                top: `${cursorPosition.y}px`,
            }}
        >
            <button>
                Start 14-Day Free Trial
                <LazyLoadImage src={btnarrow} alt='arrow' />
            </button>

        </div>


    );
};

export default CustomCursor;