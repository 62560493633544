import React from 'react';
import HBanner from '../Components/HBanner';
import HomeSection3 from '../Components/HomeSection3';
import HomeSection4 from '../Components/HomeSection4';
import HomeSection5 from '../Components/HomeSection5';
import HomeSection6 from '../Components/HomeSection6';

const Homepage = ({ textColor , bColor , btcolor, cTcolor , borderCcolor}) => {
  return (
    <div className='homepage'>
      <HBanner textColor={textColor}  />
      <HomeSection3 textColor={textColor} bColor={bColor} btcolor={btcolor} cTcolor={cTcolor} borderCcolor={ borderCcolor} />
      <HomeSection4 />
      <HomeSection5 textColor={textColor} />
      <HomeSection6 />
    </div>
  );
};

export default Homepage;
