import axios from 'axios';

// Create an instance of axios with default settings
const axiosInstance = axios.create({

  baseURL: "https://autonexxus-gms-spring-886977168513.us-central1.run.app", // Replace with your API base URL
  // baseURL: "http://localhost:8080", // Replace with your API base URL
  // timeout: 10000, // Optional: Set a timeout for requests
  headers: {
    'Content-Type': 'application/json',
    // Add other default headers if needed
  }
});
console.log("GMS Spring URL : "+process.env.GMS_SPRING_URL)

// Optional: Add an interceptor to handle requests or responses
axiosInstance.interceptors.request.use(
  config => {
    // Add authentication token or modify request config if needed
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Handle errors globally
    return Promise.reject(error);
  }
);

export default axiosInstance;
