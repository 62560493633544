import axiosInstance from './config/axiosConfig';


// export function addToWaitList(user){
//     const businessContact = {
//         id: "605c72ef1d4f6a1b7c8a9e24",
//         fullName: "John Doe",
//         businessName: "Doe Automotive Services",
//         noOfGarages: 5,
//         city: 101,  // This could be a string if representing city names or codes
//         email: "johndoe@example.com",
//         mobileNumber: 1234567890
//     };
// }

// Function to get user data
export const addToWaitList = async (userId) => {
    const businessContact = {
        fullName: userId.fullName,
        businessName: userId.businessName,
        noOfGarages: userId.noOfGarages,
        city: userId.city,  // This could be a string if representing city names or codes
        email: userId.email,
        mobileNumber: userId.mobileNumber,
        timestamp:new Date()

    };
    try {
      const response = await axiosInstance.post(`/wait-list/business`,businessContact);
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error; // Re-throw error to be handled by calling code
    }
  };