import "./Assets/Css/Style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import TopNavbar from "./Components/layout/TopNavbar";
import Footer from "./Components/layout/Footer";
import HomeSection7 from "./Components/HomeSection7";
import JoinModal from "./Components/layout/JoinModal";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import { useEffect, useState } from "react";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import DataUsage from "./Pages/DataUsage";

function App() {
  const [bgColor, setBgColor] = useState("");
  const [fixedBgColor, setFixedBgColor] = useState(false);
  const [borderColor, setBorderColor] = useState("");
  const [fixedBorderColor, setFixedBorderColor] = useState(false);
  const [btextColor, setbtextColor] = useState("");
  const [fixedbtextColor, setFixedbtextColor] = useState(false);
  const [ctextColor, setCTextColor] = useState("");
  const [fixedctextColor, setFixedCTextColor] = useState(false);
  const [bordercardcolor, setBorderCardColor] = useState("");
  const [fixedbordercardcolor, setFixedBorderCardColor] = useState(false);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const revolutionaryDiv = document.getElementById("div-revolutionary");
      const navbar = document.querySelector(".navbar");

      if (!revolutionaryDiv || !navbar) return;

      const revolutionaryRect = revolutionaryDiv.getBoundingClientRect();
      const navbarRect = navbar.getBoundingClientRect();

      const navbarBottom = navbarRect.bottom;
      const revolutionaryTop = revolutionaryRect.top;

      if (navbarBottom >= revolutionaryTop) {
        setBgColor("black");
        setBorderColor("black");
        setbtextColor("black");
        setCTextColor("black");
        setBorderCardColor("black");
        setFixedBgColor(true);
        setFixedBorderColor(true);
        setFixedbtextColor(true);
        setFixedCTextColor(true);
        setFixedBorderCardColor(true);
        setIsDark(true);
      }
      else if (navbarBottom < revolutionaryTop && fixedBgColor) {
        setBgColor("");
        setBorderColor("");
        setbtextColor("");
        setCTextColor("");
        setBorderCardColor("");
        setFixedBgColor(false);
        setFixedBorderColor(false);
        setFixedbtextColor(false);
        setFixedCTextColor(false);
        setFixedBorderCardColor(false);
        setIsDark(false);
      }

    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fixedBgColor, fixedBorderColor, fixedbtextColor, fixedctextColor, fixedbordercardcolor]);

  const appClassName = isDark ? "App dark-mode" : "App";

  const textColor = bgColor === "black" ? "#EEF2F3" : "#1A2B1A";
  const bColor = borderColor === "black" ? "#ADF07559" : "#537E30";
  const btcolor = btextColor === "black" ? "#ADF075" : "#537E30";
  const cTcolor = ctextColor === "black" ? "#C2C6C7" : "#454A45";
  const borderCcolor = bordercardcolor === "black" ? "red" : "blue";

  return (
    <div className={`${appClassName} ${bgColor} ${borderColor} ${btcolor} ${cTcolor} ${borderCcolor}`}>
      <BrowserRouter>
        <TopNavbar triggerSectionId={["section1", "section2", "section3"]} />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage
                textColor={textColor}
                bColor={bColor}
                btcolor={btcolor}
                cTcolor={cTcolor}
                borderCcolor={borderCcolor}
              />}
          />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-usage-policy" element={<DataUsage/>}/>
        </Routes>
        <HomeSection7 />
        <Footer />
        <JoinModal />
      </BrowserRouter>
    </div>
  );
}

export default App;
