import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const HomeSection4 = ({ textColor }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust the threshold as needed
    });

    const [counts, setCounts] = useState({ years: 0, technicians: 0, hours: 0 });

    useEffect(() => {
        if (inView) {
            const duration = 1000;
            const targetValues = { years: 15, technicians: 50, hours: 100 };

            const increments = {
                years: targetValues.years / (duration / 50),
                technicians: targetValues.technicians / (duration / 50),
                hours: targetValues.hours / (duration / 50),
            };

            let years = 0, technicians = 0, hours = 0;
            const interval = setInterval(() => {
                years = Math.min(years + increments.years, targetValues.years);
                technicians = Math.min(technicians + increments.technicians, targetValues.technicians);
                hours = Math.min(hours + increments.hours, targetValues.hours);

                setCounts({ years: Math.round(years), technicians: Math.round(technicians), hours: Math.round(hours) });

                if (years === targetValues.years && technicians === targetValues.technicians && hours === targetValues.hours) {
                    clearInterval(interval);
                }
            }, 50);
        }
    }, [inView]);

    return (
        <div id="home-section4" className='h-section-4 w-100 flex justify-center items-center bg-[#ADF075]' ref={ref}>
            <div className='h-sec4-banner lg:w-[80%] md:w-[90%] w-[90%] pt-[72px] pb-[56px] px-[0]'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-12'>
                        <div className='b-right-4 pr-[15px]'>
                            <h2 className='h-text-1 font-bold text-[#080C08]' style={{lineHeight:"170%"}}>
                                Guided by Real-World Feedback
                            </h2>
                            <p className='p-text-1  font-medium text-[#080C08] mt-[8px]' style={{ opacity: "75%", lineHeight:"148%" }}>
                                With over 15 years of experience in garage management and software development, and insights gathered from consulting with 50+ technicians and garages, our solution is finely tuned to address the industry's most pressing needs.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-12'>
                        <div className='b-left-4'>
                            <div className='row g-4'>
                                <div className='col-lg-4 col-md-4 col-12 '>
                                    <div className='card p=[10px] bg-transparent border-0 '>
                                        <h3 className='h-text-2 font-bold text-[#1A2B1A] mb-0 text-center]'>{counts.years}+</h3>
                                        <p className='p-text-2 text-[16px] font-medium text-[#080C08] mb-0 mt-[8px] lg:text-start md:text-center]' style={{ opacity: "75%", lineHeight:"128%"  }}>Years of Industry <br /> Experience</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='card p=[10px] bg-transparent border-0'>
                                        <h3 className='h-text-2  font-bold text-[#1A2B1A] mb-0 lg:text-start text-center]'>{counts.technicians}+</h3>
                                        <p className='p-text-2 text-[16px] font-medium text-[#080C08] mb-0 mt-[8px] lg:text-start text-center]' style={{ opacity: "75%", lineHeight:"128%" }}>Technicians and <br /> Garages Consulted</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-12'>
                                    <div className='card p=[10px] bg-transparent border-0'>
                                        <h3 className='h-text-2 font-bold text-[#1A2B1A] mb-0 lg:text-start md:text-center]'>{counts.hours}+</h3>
                                        <p className='p-text-2 text-[16px] font-medium text-[#080C08] mb-0 mt-[8px] lg:text-start md:text-center]' style={{ opacity: "75%", lineHeight:"128%" }}>Hours of <br /> User Testing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeSection4;
