import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import btnarrow from "../../Assets/Images/r-arrow-round.svg"
import logo from "../../Assets/Images/logoblack.svg"
import facebook from "../../Assets/Images/fb.svg"
import instagram from "../../Assets/Images/ig.svg"
import twitter from "../../Assets/Images/twitter.svg"
import linkedin from "../../Assets/Images/lI.svg"


const Footer = () => {
    return (
        <div className='footer w-100 flex flex-column justify-center items-center bg-[#eef2f3]'>
            <div className='footer-ban w-[90%] py-[30px]  px-[0] border-b-[1px] border-solid border-[#c3cbc7]'>
                <div className='row g-2'>
                    <div className='col-lg-3 col-md-6 col-12'>
                        <div className='footer-col-1 flex flex-col justify-center items-start gap-[26px] mt-[20px]'>
                            <LazyLoadImage src={logo} alt='logo' className='h-[20px]' />
                            <div className='flex flex-col justify-center items-start gap-[10px]'>
                                <p className='p-text-1  font-medium text-[#6a6e6a]' style={{ letterSpacing: "1px" }}>All-in-One Garage <br className='break' />
                                    Management System</p>
                                <div className='social-links flex justify-between items-start gap-[22px]'>
                                    <Link to="https://m.facebook.com/autonexxus/">
                                        <LazyLoadImage src={facebook} alt='facebook' />
                                    </Link>
                                    <Link to="https://www.instagram.com/autonexxus.app">
                                        <LazyLoadImage src={instagram} alt='instagram' />
                                    </Link>
                                    <Link to="https://x.com/autonexxus">
                                        <LazyLoadImage src={twitter} alt='twitter' />
                                    </Link>
                                    <Link to="https://www.linkedin.com/company/autonexxus/">
                                        <LazyLoadImage src={linkedin} alt='linkedin' />
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12'>
                        <div className='footer-col-2 flex flex-column justify-center items-start gap-[8px]  mt-[20px]'>
                            <Link to="/about-us" reloadDocument className='foot-link  font-medium text-[#6a6e6a] text-start no-underline hover:text-[#080c08] hover-target' style={{ letterSpacing: "0.5px" }}>About</Link>
                            <Link to="/contact" reloadDocument className='foot-link    font-medium  text-[#6a6e6a] text-start no-underline hover:text-[#080c08] hover-target' style={{ letterSpacing: "0.5px" }}>Contact Us</Link>
                            <Link to="/privacy-policy" reloadDocument className='foot-link   font-medium  text-[#6a6e6a] text-start no-underline hover:text-[#080c08] hover-target' style={{ letterSpacing: "0.5px" }}>Privacy Policy</Link>
                            <Link to="/data-usage-policy" reloadDocument className='foot-link   font-medium text-[#6a6e6a] text-start no-underline hover:text-[#080c08] hover-target' style={{ letterSpacing: "0.5px" }}>Data Usage Policy</Link>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 '>
                        <div className='footer-col-3 flex flex-col justify-center items-start gap-[12px]  mt-[20px]'>
                            <h5 className='h-text-1  font-bold text-[#080c08] text-start'>Address</h5>
                            <p className='p-text-1 font-normal text-[#6a6e6a]' style={{ lineHeight: "160%", letterSpacing: "1px" }}>D.NO.61. VP Complex, <br className='break' />Alagappa Nagar, <br className='break' />Madurai, India.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12'>
                        <div className='footer-col-4 flex flex-col justify-center items-start gap-[8px]  mt-[20px]'>
                            <p className='p-text-1 text-[#6a6e6a] mb-[5px]' style={{ lineHeight: "160%", letterSpacing: "1px" }}>Enquiry: +91 93602 10570</p>
                            <h5 className='h-text-2 font-bold text-[#080c08] items-start' style={{ lineHeight: "160%" }}>Ready to Revolutionize Your Garage?</h5>
                            <button className='btn-join-foot h-[40px] py-[0px] px-[15px] flex justify-between items-center gap-[5px] rounded-[40px]  font-semibold bg-[#adf075] border-[1.5px] border-solid border-[black]' data-bs-toggle="modal" data-bs-target="#joinModal" >Join Our Waiting List  <LazyLoadImage src={btnarrow} alt='arrow' /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copy-right p-[30px]'>
                <p className='p-copy text-[14px] font-medium text-[#6a6e6a] text-center' style={{ letterSpacing: "1px" }}>© 2024 Auto Nexxus Private Limited. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer
