import React, { useState, useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import logoblack from "../../Assets/Images/logoblack.svg";
import close from "../../Assets/Images/close.svg";
import { MdOutlineClear } from "react-icons/md";
import axios from 'axios';
import * as waitListService from '../../service/WaitListService'


const locationData = {
    "Andhra Pradesh": [
        "Visakhapatnam",
        "Vijayawada",
        "Guntur",
        "Nellore",
        "Kurnool",
        "Rajahmundry",
        "Kakinada",
        "Tirupati",
        "Anantapur",
        "Kadapa",
        "Vizianagaram",
        "Eluru",
        "Ongole",
        "Nandyal",
        "Machilipatnam",
        "Adoni",
        "Tenali",
        "Chittoor",
        "Hindupur",
        "Proddatur",
        "Bhimavaram",
        "Madanapalle",
        "Guntakal",
        "Dharmavaram",
        "Gudivada",
        "Srikakulam",
        "Narasaraopet",
        "Rajampet",
        "Tadpatri",
        "Tadepalligudem",
        "Chilakaluripet",
        "Yemmiganur",
        "Kadiri",
        "Chirala",
        "Anakapalle",
        "Kavali",
        "Palacole",
        "Sullurpeta",
        "Tanuku",
        "Rayachoti",
        "Srikalahasti",
        "Bapatla",
        "Naidupet",
        "Nagari",
        "Gudur",
        "Vinukonda",
        "Narasapuram",
        "Nuzvid",
        "Markapur",
        "Ponnur",
        "Kandukur",
        "Bobbili",
        "Rayadurg",
        "Samalkot",
        "Jaggaiahpet",
        "Tuni",
        "Amalapuram",
        "Bheemunipatnam",
        "Venkatagiri",
        "Sattenapalle",
        "Pithapuram",
        "Palasa Kasibugga",
        "Parvathipuram",
        "Macherla",
        "Gooty",
        "Salur",
        "Mandapeta",
        "Jammalamadugu",
        "Peddapuram",
        "Punganur",
        "Nidadavole",
        "Repalle",
        "Ramachandrapuram",
        "Kovvur",
        "Tiruvuru",
        "Uravakonda",
        "Narsipatnam",
        "Yerraguntla",
        "Pedana",
        "Puttur",
        "Renigunta",
        "Rajam",
        "Srisailam Project (Right Flank Colony) Township"
    ],
    "Arunachal Pradesh": [
        "Naharlagun",
        "Pasighat"
    ],
    "Assam": [
        "Guwahati",
        "Silchar",
        "Dibrugarh",
        "Nagaon",
        "Tinsukia",
        "Jorhat",
        "Bongaigaon City",
        "Dhubri",
        "Diphu",
        "North Lakhimpur",
        "Tezpur",
        "Karimganj",
        "Sibsagar",
        "Goalpara",
        "Barpeta",
        "Lanka",
        "Lumding",
        "Mankachar",
        "Nalbari",
        "Rangia",
        "Margherita",
        "Mangaldoi",
        "Silapathar",
        "Mariani",
        "Marigaon"
    ],
    "Bihar": [
        "Patna",
        "Gaya",
        "Bhagalpur",
        "Muzaffarpur",
        "Darbhanga",
        "Arrah",
        "Begusarai",
        "Chhapra",
        "Katihar",
        "Munger",
        "Purnia",
        "Saharsa",
        "Sasaram",
        "Hajipur",
        "Dehri-on-Sone",
        "Bettiah",
        "Motihari",
        "Bagaha",
        "Siwan",
        "Kishanganj",
        "Jamalpur",
        "Buxar",
        "Jehanabad",
        "Aurangabad",
        "Lakhisarai",
        "Nawada",
        "Jamui",
        "Sitamarhi",
        "Araria",
        "Gopalganj",
        "Madhubani",
        "Masaurhi",
        "Samastipur",
        "Mokameh",
        "Supaul",
        "Dumraon",
        "Arwal",
        "Forbesganj",
        "BhabUrban Agglomeration",
        "Narkatiaganj",
        "Naugachhia",
        "Madhepura",
        "Sheikhpura",
        "Sultanganj",
        "Raxaul Bazar",
        "Ramnagar",
        "Mahnar Bazar",
        "Warisaliganj",
        "Revelganj",
        "Rajgir",
        "Sonepur",
        "Sherghati",
        "Sugauli",
        "Makhdumpur",
        "Maner",
        "Rosera",
        "Nokha",
        "Piro",
        "Rafiganj",
        "Marhaura",
        "Mirganj",
        "Lalganj",
        "Murliganj",
        "Motipur",
        "Manihari",
        "Sheohar",
        "Maharajganj",
        "Silao",
        "Barh",
        "Asarganj"
    ],

    "Chhattisgarh": [
        "Raipur",
        "Bhilai Nagar",
        "Korba",
        "Bilaspur",
        "Durg",
        "Rajnandgaon",
        "Jagdalpur",
        "Raigarh",
        "Ambikapur",
        "Mahasamund",
        "Dhamtari",
        "Chirmiri",
        "Bhatapara",
        "Dalli-Rajhara",
        "Naila Janjgir",
        "Tilda Newra",
        "Mungeli",
        "Manendragarh",
        "Sakti"
    ],
    "Goa": [
        "Marmagao",
        "Panaji",
        "Margao",
        "Mapusa"
    ],

    "Gujarat": [
        "Ahmedabad",
        "Surat",
        "Vadodara",
        "Rajkot",
        "Bhavnagar",
        "Jamnagar",
        "Nadiad",
        "Porbandar",
        "Anand",
        "Morvi",
        "Mahesana",
        "Bharuch",
        "Vapi",
        "Navsari",
        "Veraval",
        "Bhuj",
        "Godhra",
        "Palanpur",
        "Valsad",
        "Patan",
        "Deesa",
        "Amreli",
        "Anjar",
        "Dhoraji",
        "Khambhat",
        "Mahuva",
        "Keshod",
        "Wadhwan",
        "Ankleshwar",
        "Savarkundla",
        "Kadi",
        "Visnagar",
        "Upleta",
        "Una",
        "Sidhpur",
        "Unjha",
        "Mangrol",
        "Viramgam",
        "Modasa",
        "Palitana",
        "Petlad",
        "Kapadvanj",
        "Sihor",
        "Wankaner",
        "Limbdi",
        "Mandvi",
        "Thangadh",
        "Vyara",
        "Padra",
        "Lunawada",
        "Rajpipla",
        "Vapi",
        "Umreth",
        "Sanand",
        "Rajula",
        "Radhanpur",
        "Mahemdabad",
        "Ranavav",
        "Tharad",
        "Mansa",
        "Umbergaon",
        "Talaja",
        "Vadnagar",
        "Manavadar",
        "Salaya",
        "Vijapur",
        "Pardi",
        "Rapar",
        "Songadh",
        "Lathi",
        "Adalaj",
        "Chhapra",
        "Gandhinagar"
    ],
    "Haryana": [
        "Faridabad", "Gurgaon", "Hisar", "Rohtak", "Panipat", "Karnal", "Sonipat", "Yamunanagar",
        "Panchkula", "Bhiwani", "Bahadurgarh", "Jind", "Sirsa", "Thanesar", "Kaithal", "Palwal",
        "Rewari", "Hansi", "Narnaul", "Fatehabad", "Gohana", "Tohana", "Narwana", "Mandi Dabwali",
        "Charkhi Dadri", "Shahbad", "Pehowa", "Samalkha", "Pinjore", "Ladwa", "Sohna", "Safidon",
        "Taraori", "Mahendragarh", "Ratia", "Rania", "Sarsod"
    ],
    "Himachal Pradesh": [
        "Shimla",
        "Mandi",
        "Solan",
        "Nahan",
        "Sundarnagar",
        "Palampur",
        "Kullu"
    ],

    "Jammu and Kashmir": [
        "Srinagar",
        "Jammu",
        "Baramula",
        "Anantnag",
        "Sopore",
        "KathUrban Agglomeration",
        "Rajauri",
        "Punch",
        "Udhampur"
    ],

    "Jharkhand": [
        "Dhanbad",
        "Ranchi",
        "Jamshedpur",
        "Bokaro Steel City",
        "Deoghar",
        "Phusro",
        "Adityapur",
        "Hazaribag",
        "Giridih",
        "Ramgarh",
        "Jhumri Tilaiya",
        "Saunda",
        "Sahibganj",
        "Medininagar (Daltonganj)",
        "Chaibasa",
        "Chatra",
        "Gumia",
        "Dumka",
        "Madhupur",
        "Chirkunda",
        "Pakaur",
        "Simdega",
        "Musabani",
        "Mihijam",
        "Patratu",
        "Lohardaga",
        "Tenu dam-cum-Kathhara"
    ],
    "Karnataka": [
        "Bengaluru",
        "Hubli-Dharwad",
        "Belagavi",
        "Mangaluru",
        "Davanagere",
        "Ballari",
        "Mysore",
        "Tumkur",
        "Shivamogga",
        "Raayachuru",
        "Robertson Pet",
        "Kolar",
        "Mandya",
        "Udupi",
        "Chikkamagaluru",
        "Karwar",
        "Ranebennuru",
        "Ranibennur",
        "Ramanagaram",
        "Gokak",
        "Yadgir",
        "Rabkavi Banhatti",
        "Shahabad",
        "Sirsi",
        "Sindhnur",
        "Tiptur",
        "Arsikere",
        "Nanjangud",
        "Sagara",
        "Sira",
        "Puttur",
        "Athni",
        "Mulbagal",
        "Surapura",
        "Siruguppa",
        "Mudhol",
        "Sidlaghatta",
        "Shahpur",
        "Saundatti-Yellamma",
        "Wadi",
        "Manvi",
        "Nelamangala",
        "Lakshmeshwar",
        "Ramdurg",
        "Nargund",
        "Tarikere",
        "Malavalli",
        "Savanur",
        "Lingsugur",
        "Vijayapura",
        "Sankeshwara",
        "Madikeri",
        "Talikota",
        "Sedam",
        "Shikaripur",
        "Mahalingapura",
        "Mudalagi",
        "Muddebihal",
        "Pavagada",
        "Malur",
        "Sindhagi",
        "Sanduru",
        "Afzalpur",
        "Maddur",
        "Madhugiri",
        "Tekkalakote",
        "Terdal",
        "Mudabidri",
        "Magadi",
        "Navalgund",
        "Shiggaon",
        "Shrirangapattana",
        "Sindagi",
        "Sakaleshapura",
        "Srinivaspur",
        "Ron",
        "Mundargi",
        "Sadalagi",
        "Piriyapatna",
        "Adyar"
    ],
    "Kerala": [
        "Thiruvananthapuram",
        "Kochi",
        "Kozhikode",
        "Kollam",
        "Thrissur",
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Ponnani",
        "Vatakara",
        "Kanhangad",
        "Taliparamba",
        "Koyilandy",
        "Neyyattinkara",
        "Kayamkulam",
        "Nedumangad",
        "Kannur",
        "Tirur",
        "Kottayam",
        "Kasaragod",
        "Kunnamkulam",
        "Ottappalam",
        "Thiruvalla",
        "Thodupuzha",
        "Chalakudy",
        "Changanassery",
        "Punalur",
        "Nilambur",
        "Cherthala",
        "Perinthalmanna",
        "Mattannur",
        "Shoranur",
        "Varkala",
        "Paravoor",
        "Pathanamthitta",
        "Peringathur",
        "Attingal",
        "Kodungallur",
        "Pappinisseri",
        "Chittur-Thathamangalam",
        "Muvattupuzha",
        "Adoor",
        "Mavelikkara",
        "Mavoor",
        "Perumbavoor",
        "Vaikom",
        "Palai",
        "Panniyannur",
        "Guruvayoor",
        "Puthuppally",
        "Panamattom"
    ],

    "Madhya Pradesh": [
        "Indore",
        "Bhopal",
        "Jabalpur",
        "Gwalior",
        "Ujjain",
        "Sagar",
        "Ratlam",
        "Satna",
        "Murwara (Katni)",
        "Morena",
        "Singrauli",
        "Rewa",
        "Vidisha",
        "Ganjbasoda",
        "Shivpuri",
        "Mandsaur",
        "Neemuch",
        "Nagda",
        "Itarsi",
        "Sarni",
        "Sehore",
        "Mhow Cantonment",
        "Seoni",
        "Balaghat",
        "Ashok Nagar",
        "Tikamgarh",
        "Shahdol",
        "Pithampur",
        "Alirajpur",
        "Mandla",
        "Sheopur",
        "Shajapur",
        "Panna",
        "Raghogarh-Vijaypur",
        "Sendhwa",
        "Sidhi",
        "Pipariya",
        "Shujalpur",
        "Sironj",
        "Pandhurna",
        "Nowgong",
        "Mandideep",
        "Sihora",
        "Raisen",
        "Lahar",
        "Maihar",
        "Sanawad",
        "Sabalgarh",
        "Umaria",
        "Porsa",
        "Narsinghgarh",
        "Malaj Khand",
        "Sarangpur",
        "Mundi",
        "Nepanagar",
        "Pasan",
        "Mahidpur",
        "Seoni-Malwa",
        "Rehli",
        "Manawar",
        "Rahatgarh",
        "Panagar",
        "Wara Seoni",
        "Tarana",
        "Sausar",
        "Rajgarh",
        "Niwari",
        "Mauganj",
        "Manasa",
        "Nainpur",
        "Prithvipur",
        "Sohagpur",
        "Nowrozabad (Khodargama)",
        "Shamgarh",
        "Maharajpur",
        "Multai",
        "Pali",
        "Pachore",
        "Rau",
        "Mhowgaon",
        "Vijaypur",
        "Narsinghgarh"
    ],
    "Maharashtra": [
        "Mumbai",
        "Pune",
        "Nagpur",
        "Thane",
        "Nashik",
        "Kalyan-Dombivali",
        "Vasai-Virar",
        "Solapur",
        "Mira-Bhayandar",
        "Bhiwandi",
        "Amravati",
        "Nanded-Waghala",
        "Sangli",
        "Malegaon",
        "Akola",
        "Latur",
        "Dhule",
        "Ahmednagar",
        "Ichalkaranji",
        "Parbhani",
        "Panvel",
        "Yavatmal",
        "Achalpur",
        "Osmanabad",
        "Nandurbar",
        "Satara",
        "Wardha",
        "Udgir",
        "Aurangabad",
        "Amalner",
        "Akot",
        "Pandharpur",
        "Shrirampur",
        "Parli",
        "Washim",
        "Ambejogai",
        "Manmad",
        "Ratnagiri",
        "Uran Islampur",
        "Pusad",
        "Sangamner",
        "Shirpur-Warwade",
        "Malkapur",
        "Wani",
        "Lonavla",
        "Talegaon Dabhade",
        "Anjangaon",
        "Umred",
        "Palghar",
        "Shegaon",
        "Ozar",
        "Phaltan",
        "Yevla",
        "Shahade",
        "Vita",
        "Umarkhed",
        "Warora",
        "Pachora",
        "Tumsar",
        "Manjlegaon",
        "Sillod",
        "Arvi",
        "Nandura",
        "Vaijapur",
        "Wadgaon Road",
        "Sailu",
        "Murtijapur",
        "Tasgaon",
        "Mehkar",
        "Yawal",
        "Pulgaon",
        "Nilanga",
        "Wai",
        "Umarga",
        "Paithan",
        "Rahuri",
        "Nawapur",
        "Tuljapur",
        "Morshi",
        "Purna",
        "Satana",
        "Pathri",
        "Sinnar",
        "Uchgaon",
        "Uran",
        "Pen",
        "Karjat",
        "Manwath",
        "Partur",
        "Sangole",
        "Mangrulpir",
        "Risod",
        "Shirur",
        "Savner",
        "Sasvad",
        "Pandharkaoda",
        "Talode",
        "Shrigonda",
        "Shirdi",
        "Raver",
        "Mukhed",
        "Rajura",
        "Vadgaon Kasba",
        "Tirora",
        "Mahad",
        "Lonar",
        "Sawantwadi",
        "Pathardi",
        "Pauni",
        "Ramtek",
        "Mul",
        "Soyagaon",
        "Mangalvedhe",
        "Narkhed",
        "Shendurjana",
        "Patur",
        "Mhaswad",
        "Loha",
        "Nandgaon",
        "Warud"
    ],
    "Manipur": [
        "Imphal",
        "Thoubal",
        "Lilong",
        "Mayang Imphal"
    ],
    "Meghalaya": [
        "Shillong",
        "Tura",
        "Nongstoin"
    ],
    "Mizoram": [
        "Aizawl",
        "Lunglei",
        "Saiha"
    ],
    "Nagaland": [
        "Dimapur",
        "Kohima",
        "Zunheboto",
        "Tuensang",
        "Wokha",
        "Mokokchung"
    ],
    "Odisha": [
        "Bhubaneswar",
        "Cuttack",
        "Raurkela",
        "Brahmapur",
        "Sambalpur",
        "Puri",
        "Baleshwar Town",
        "Baripada Town",
        "Bhadrak",
        "Balangir",
        "Jharsuguda",
        "Bargarh",
        "Paradip",
        "Bhawanipatna",
        "Dhenkanal",
        "Barbil",
        "Kendujhar",
        "Sunabeda",
        "Rayagada",
        "Jatani",
        "Byasanagar",
        "Kendrapara",
        "Rajagangapur",
        "Parlakhemundi",
        "Talcher",
        "Sundargarh",
        "Phulabani",
        "Pattamundai",
        "Titlagarh",
        "Nabarangapur",
        "Soro",
        "Malkangiri",
        "Rairangpur",
        "Tarbha"
    ],

    "Punjab": [
        "Ludhiana",
        "Patiala",
        "Amritsar",
        "Jalandhar",
        "Bathinda",
        "Pathankot",
        "Hoshiarpur",
        "Batala",
        "Moga",
        "Malerkotla",
        "Khanna",
        "Mohali",
        "Barnala",
        "Firozpur",
        "Phagwara",
        "Kapurthala",
        "Zirakpur",
        "Kot Kapura",
        "Faridkot",
        "Muktsar",
        "Rajpura",
        "Sangrur",
        "Fazilka",
        "Gurdaspur",
        "Kharar",
        "Gobindgarh",
        "Mansa",
        "Malout",
        "Nabha",
        "Tarn Taran",
        "Jagraon",
        "Sunam",
        "Dhuri",
        "Firozpur Cantt.",
        "Sirhind Fatehgarh Sahib",
        "Rupnagar",
        "Jalandhar Cantt.",
        "Samana",
        "Nawanshahr",
        "Rampura Phul",
        "Nangal",
        "Nakodar",
        "Zira",
        "Patti",
        "Raikot",
        "Longowal",
        "Urmar Tanda",
        "Morinda, India",
        "Phillaur",
        "Pattran",
        "Qadian",
        "Sujanpur",
        "Mukerian",
        "Talwara"
    ],

    "Rajasthan": [
        "Jaipur",
        "Jodhpur",
        "Bikaner",
        "Udaipur",
        "Ajmer",
        "Bhilwara",
        "Alwar",
        "Bharatpur",
        "Pali",
        "Barmer",
        "Sikar",
        "Tonk",
        "Sadulpur",
        "Sawai Madhopur",
        "Nagaur",
        "Makrana",
        "Sujangarh",
        "Sardarshahar",
        "Ladnu",
        "Ratangarh",
        "Nokha",
        "Nimbahera",
        "Suratgarh",
        "Rajsamand",
        "Lachhmangarh",
        "Rajgarh (Churu)",
        "Nasirabad",
        "Nohar",
        "Phalodi",
        "Nathdwara",
        "Pilani",
        "Merta City",
        "Sojat",
        "Neem-Ka-Thana",
        "Sirohi",
        "Pratapgarh",
        "Rawatbhata",
        "Sangaria",
        "Lalsot",
        "Pilibanga",
        "Pipar City",
        "Taranagar",
        "Vijainagar, Ajmer",
        "Sumerpur",
        "Sagwara",
        "Ramganj Mandi",
        "Lakheri",
        "Udaipurwati",
        "Losal",
        "Sri Madhopur",
        "Ramngarh",
        "Rawatsar",
        "Rajakhera",
        "Shahpura",
        "Shahpura",
        "Raisinghnagar",
        "Malpura",
        "Nadbai",
        "Sanchore",
        "Nagar",
        "Rajgarh (Alwar)",
        "Sheoganj",
        "Sadri",
        "Todaraisingh",
        "Todabhim",
        "Reengus",
        "Rajaldesar",
        "Sadulshahar",
        "Sambhar",
        "Prantij",
        "Mount Abu",
        "Mangrol",
        "Phulera",
        "Mandawa",
        "Pindwara",
        "Mandalgarh",
        "Takhatgarh"
    ],

    "Andaman and Nicobar Islands": ["Port Blair"],

    "Tamil Nadu": [
        "Chennai",
        "Coimbatore",
        "Madurai",
        "Tiruchirappalli",
        "Salem",
        "Tirunelveli",
        "Tiruppur",
        "Ranipet",
        "Nagercoil",
        "Thanjavur",
        "Vellore",
        "Kancheepuram",
        "Erode",
        "Tiruvannamalai",
        "Pollachi",
        "Rajapalayam",
        "Sivakasi",
        "Pudukkottai",
        "Neyveli (TS)",
        "Nagapattinam",
        "Viluppuram",
        "Tiruchengode",
        "Vaniyambadi",
        "Theni Allinagaram",
        "Udhagamandalam",
        "Aruppukkottai",
        "Paramakudi",
        "Arakkonam",
        "Virudhachalam",
        "Srivilliputhur",
        "Tindivanam",
        "Virudhunagar",
        "Karur",
        "Valparai",
        "Sankarankovil",
        "Tenkasi",
        "Palani",
        "Pattukkottai",
        "Tirupathur",
        "Ramanathapuram",
        "Udumalaipettai",
        "Gobichettipalayam",
        "Thiruvarur",
        "Thiruvallur",
        "Panruti",
        "Namakkal",
        "Thirumangalam",
        "Vikramasingapuram",
        "Nellikuppam",
        "Rasipuram",
        "Tiruttani",
        "Nandivaram-Guduvancheri",
        "Periyakulam",
        "Pernampattu",
        "Vellakoil",
        "Sivaganga",
        "Vadalur",
        "Rameshwaram",
        "Tiruvethipuram",
        "Perambalur",
        "Usilampatti",
        "Vedaranyam",
        "Sathyamangalam",
        "Puliyankudi",
        "Nanjikottai",
        "Thuraiyur",
        "Sirkali",
        "Tiruchendur",
        "Periyasemur",
        "Sattur",
        "Vandavasi",
        "Tharamangalam",
        "Tirukkoyilur",
        "Oddanchatram",
        "Palladam",
        "Vadakkuvalliyur",
        "Tirukalukundram",
        "Uthamapalayam",
        "Surandai",
        "Sankari",
        "Shenkottai",
        "Vadipatti",
        "Sholingur",
        "Tirupathur",
        "Manachanallur",
        "Viswanatham",
        "Polur",
        "Panagudi",
        "Uthiramerur",
        "Thiruthuraipoondi",
        "Pallapatti",
        "Ponneri",
        "Lalgudi",
        "Natham",
        "Unnamalaikadai",
        "P.N.Patti",
        "Tharangambadi",
        "Tittakudi",
        "Pacode",
        "O' Valley",
        "Suriyampalayam",
        "Sholavandan",
        "Thammampatti",
        "Namagiripettai",
        "Peravurani",
        "Parangipettai",
        "Pudupattinam",
        "Pallikonda",
        "Sivagiri",
        "Punjaipugalur",
        "Padmanabhapuram",
        "Thirupuvanam"
    ],
    "Telangana": [
        "Hyderabad",
        "Warangal",
        "Nizamabad",
        "Karimnagar",
        "Ramagundam",
        "Khammam",
        "Mahbubnagar",
        "Mancherial",
        "Adilabad",
        "Suryapet",
        "Jagtial",
        "Miryalaguda",
        "Nirmal",
        "Kamareddy",
        "Kothagudem",
        "Bodhan",
        "Palwancha",
        "Mandamarri",
        "Koratla",
        "Sircilla",
        "Tandur",
        "Siddipet",
        "Wanaparthy",
        "Kagaznagar",
        "Gadwal",
        "Sangareddy",
        "Bellampalle",
        "Bhongir",
        "Vikarabad",
        "Jangaon",
        "Bhadrachalam",
        "Bhainsa",
        "Farooqnagar",
        "Medak",
        "Narayanpet",
        "Sadasivpet",
        "Yellandu",
        "Manuguru",
        "Kyathampalle",
        "Nagarkurnool"
    ],
    "Telangana": [
        "Hyderabad",
        "Warangal",
        "Nizamabad",
        "Karimnagar",
        "Ramagundam",
        "Khammam",
        "Mahbubnagar",
        "Mancherial",
        "Adilabad",
        "Suryapet",
        "Jagtial",
        "Miryalaguda",
        "Nirmal",
        "Kamareddy",
        "Kothagudem",
        "Bodhan",
        "Palwancha",
        "Mandamarri",
        "Koratla",
        "Sircilla",
        "Tandur",
        "Siddipet",
        "Wanaparthy",
        "Kagaznagar",
        "Gadwal",
        "Sangareddy",
        "Bellampalle",
        "Bhongir",
        "Vikarabad",
        "Jangaon",
        "Bhadrachalam",
        "Bhainsa",
        "Farooqnagar",
        "Medak",
        "Narayanpet",
        "Sadasivpet",
        "Yellandu",
        "Manuguru",
        "Kyathampalle",
        "Nagarkurnool"
    ],
    "Uttar Pradesh": [
        "Lucknow",
        "Kanpur",
        "Firozabad",
        "Agra",
        "Meerut",
        "Varanasi",
        "Allahabad",
        "Amroha",
        "Moradabad",
        "Aligarh",
        "Saharanpur",
        "Noida",
        "Loni",
        "Jhansi",
        "Shahjahanpur",
        "Rampur",
        "Modinagar",
        "Hapur",
        "Etawah",
        "Sambhal",
        "Orai",
        "Bahraich",
        "Unnao",
        "Rae Bareli",
        "Lakhimpur",
        "Sitapur",
        "Lalitpur",
        "Pilibhit",
        "Chandausi",
        "Hardoi ",
        "Azamgarh",
        "Khair",
        "Sultanpur",
        "Tanda",
        "Nagina",
        "Shamli",
        "Najibabad",
        "Shikohabad",
        "Sikandrabad",
        "Shahabad, Hardoi",
        "Pilkhuwa",
        "Renukoot",
        "Vrindavan",
        "Ujhani",
        "Laharpur",
        "Tilhar",
        "Sahaswan",
        "Rath",
        "Sherkot",
        "Kalpi",
        "Tundla",
        "Sandila",
        "Nanpara",
        "Sardhana",
        "Nehtaur",
        "Seohara",
        "Padrauna",
        "Mathura",
        "Thakurdwara",
        "Nawabganj",
        "Siana",
        "Noorpur",
        "Sikandra Rao",
        "Puranpur",
        "Rudauli",
        "Thana Bhawan",
        "Palia Kalan",
        "Zaidpur",
        "Nautanwa",
        "Zamania",
        "Shikarpur, Bulandshahr",
        "Naugawan Sadat",
        "Fatehpur Sikri",
        "Shahabad, Rampur",
        "Robertsganj",
        "Utraula",
        "Sadabad",
        "Rasra",
        "Lar",
        "Lal Gopalganj Nindaura",
        "Sirsaganj",
        "Pihani",
        "Shamsabad, Agra",
        "Rudrapur",
        "Soron",
        "SUrban Agglomerationr",
        "Samdhan",
        "Sahjanwa",
        "Rampur Maniharan",
        "Sumerpur",
        "Shahganj",
        "Tulsipur",
        "Tirwaganj",
        "PurqUrban Agglomerationzi",
        "Shamsabad, Farrukhabad",
        "Warhapur",
        "Powayan",
        "Sandi",
        "Achhnera",
        "Naraura",
        "Nakur",
        "Sahaspur",
        "Safipur",
        "Reoti",
        "Sikanderpur",
        "Saidpur",
        "Sirsi",
        "Purwa",
        "Parasi",
        "Lalganj",
        "Phulpur",
        "Shishgarh",
        "Sahawar",
        "Samthar",
        "Pukhrayan",
        "Obra",
        "Niwai",
        "Mirzapur"
    ],
    "Uttarakhand": [
        "Dehradun",
        "Hardwar",
        "Haldwani-cum-Kathgodam",
        "Srinagar",
        "Kashipur",
        "Roorkee",
        "Rudrapur",
        "Rishikesh",
        "Ramnagar",
        "Pithoragarh",
        "Manglaur",
        "Nainital",
        "Mussoorie",
        "Tehri",
        "Pauri",
        "Nagla",
        "Sitarganj",
        "Bageshwar"
    ],

    "Delhi": [
        "Delhi",
        "New Delhi"
    ],
    "Dadra and Nagar Haveli": [
        "Silvassa"
    ],
    "Puducherry": [
        "Pondicherry",
        "Karaikal",
        "Yanam",
        "Mahe"
    ],
    "Tripura": [
        "Agartala",
        "Udaipur",
        "Dharmanagar",
        "Pratapgarh",
        "Kailasahar",
        "Belonia",
        "Khowai"
    ],
    "Sikkim": [
        "Gangtok",
        "Namchi",
        "Mangan",
        "Geyzing",
        "Pelling",
        "Jorethang",
        "Yuksom",
        "Rangpo",
        "Singtam",
        "Rangli Rangliot"
    ]
}

const formatOptions = (data) => {
    return Object.entries(data).flatMap(([state, cities]) => [
        { label: state, value: state, isDisabled: true },
        ...cities.map(city => ({
            label: `${city} (${state})`,
            value: `${city} (${state})`
        }))
    ]);
};


const JoinModal = () => {
    const [isContentReplaced, setIsContentReplaced] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // ----
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const options = formatOptions(locationData);


    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
        setSearchTerm(value);
        setIsOpen(false);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClear = (e) => {
        e.stopPropagation();
        setSelectedOption('');
        setSearchTerm('');
    };


    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // ----



    const handleReplaceContent = () => {
        setIsLoading(true);
        waitListService.addToWaitList(contact).then(resp => { 
            console.log(resp.datt); 
            setIsLoading(false); 
            setIsContentReplaced(true); 
        })
    };

    useEffect(() => {
        const modalElement = document.getElementById('joinModal');
        const resetContent = () => {
            setIsContentReplaced(false);
            setIsLoading(false);
        };

        modalElement.addEventListener('hidden.bs.modal', resetContent);

        return () => {
            modalElement.removeEventListener('hidden.bs.modal', resetContent);
        };
    }, []);

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        businessName: '',
        noOfGarages: '',
        baseLocation: '',
        contactNumber: '',
    });

    //   const handleChange = (e) => {
    //     setFormData({
    //       ...formData,
    //       [e.target.name]: e.target.value,
    //     });
    //   };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await
                axios.post('https://script.google.com/macros/s/AKfycbyBOUC4NF-pRoJwxU3oCMCMaXwzSMLh1fMpnKptMyAyTDCFvuQmpqztyFFH7vSjq_WXnA/exec', formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                });
            console.log('Data submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const [contact, setContact] = useState({});

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevContact) => ({
            ...prevContact,
            [name]: value
        }));
    };



    return (
        <div className='join-modal'>
            <div className="modal fade" id="joinModal" tabIndex="-1" aria-labelledby="joinModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className='modal-topnav w-100 flex justify-center items-center'>
                                <div className='modal-navbar w-[65%] h-[55px] rounded-[80px] flex justify-between items-center py-[0] px-[7px] bg-[#d4d4d459]'>
                                    <div className='modal-start-nav pl-[15px]'>
                                        <Link to="/">
                                            <LazyLoadImage src={logoblack} alt='logo' className='logo' />
                                        </Link>
                                    </div>
                                    <button className='btn-close-btn flex justify-around items-center border-0 h-[40px] py-[0] px-[15px] gap-[5px] rounded-[40px] bg-[#080c08]' data-bs-dismiss="modal" aria-label="Close">
                                        <p className='mobile-hide text-[16px] font-semibold mb-0 text-white'>Close</p>
                                        <LazyLoadImage src={close} alt='close' className='closeimg' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body mt-3 w-100 flex items-center justify-center pt-0 bg-[#eef2f3]">
                            <div className='modal-body-content w-[90%]'>
                                {isContentReplaced ? (

                                    <div className='text-center flex flex-column justify-center items-center gap-[15px]'>
                                        <h2 className=' r-h-text-1 font-bold text-[#1a2b1a]'>
                                            You are in! Welcome to the Future <br className='break' />
                                            of Garage Management.
                                        </h2>
                                        <p className=' r-p-text-1 font-medium text-[#454a45]'>
                                            Your submission has been received successfully. As one of the early adopters of Auto Nexxus <br className='break' />
                                            GMS, you'll play a pivotal role in revolutionizing the industry.
                                        </p>
                                        <p className='r-p-text-2 font-medium text-[#454a45]'>
                                            Our team will be in touch shortly to schedule a personalized demo. Get ready to experience the next <br className='break' />
                                            generation of garage management solutions.
                                        </p>
                                        <p className='r-p-text-3 font-bold text-[#537e30]'>
                                            Note: No credit card required for the trial.
                                        </p>
                                    </div>
                                ) : (
                                    <div className='row g-2'>
                                        <div className='col-lg-6 col-md-12 col-12'>
                                            <div className='modal-col-1 h-100 flex flex-col justify-end items-start pl-[80px] pb-[50px] gap-[15px]'>
                                                <h2 className='h-text-1  font-bold text-[#1a2b1a]'>
                                                    Join the Auto <br className='break' />
                                                    Nexxus GMS <br className='break' />
                                                    Waiting List
                                                </h2>

                                                <p className='p-text-1 text-[20px] font-medium text-[#454a45]'>
                                                    Be among the first to revolutionize your <br className='break' />
                                                    garage operations. Sign up to secure your <br className='break' />
                                                    spot and receive early access updates.
                                                </p>
                                                <p className='p-text-2 text-[12px] font-bold text-[#537e30]'>
                                                    Your privacy is important to us. We'll never share your information.
                                                </p>

                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 col-12'>
                                            <div className='modal-col-2'>
                                                <div className="mb-2">
                                                    <label htmlFor="fullName" className="form-label font-medium text-[#080c08] mb-[3px]">Full Name</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="fullName"
                                                            placeholder="Enter Name"
                                                            name="fullName"
                                                            value={contact.fullName}
                                                            onChange={handleChange}
                                                            aria-describedby="basic-addon3"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-2">
                                                    <label htmlFor="businessName" className="form-label font-medium text-[#080c08] mb-[3px]">Business Name</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="businessName"
                                                            placeholder="My Garage Pvt Ltd"
                                                            name="businessName"
                                                            value={contact.businessName}
                                                            onChange={handleChange}
                                                            aria-describedby="basic-addon4"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="noOfGarages" className="form-label font-medium text-[#080c08] mb-[3px]">Number of Garages</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="number" // Changed to number type for numeric input
                                                                    className="form-control"
                                                                    id="noOfGarages"
                                                                    placeholder="Select no.of garages"
                                                                    name="noOfGarages"
                                                                    value={contact.noOfGarages}
                                                                    onChange={handleChange}
                                                                    aria-describedby="basic-addon3"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="city" className="form-label font-medium text-[#080c08] mb-[3px]">Select City</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text" // Assuming city is a string or code. Adjust as needed.
                                                                    className="form-control"
                                                                    id="city"
                                                                    placeholder="Select City"
                                                                    name="city"
                                                                    value={contact.city}
                                                                    onChange={handleChange}
                                                                    aria-describedby="basic-addon4"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="email" className="form-label font-medium text-[#080c08] mb-[3px]">Email ID</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="email" // Changed to email type for email validation
                                                                    className="form-control"
                                                                    id="email"
                                                                    placeholder="Email Address"
                                                                    name="email"
                                                                    value={contact.email}
                                                                    onChange={handleChange}
                                                                    aria-describedby="basic-addon3"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-12 col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="mobileNumber" className="form-label font-medium text-[#080c08] mb-[3px]">Mobile Number</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text" // You might want to use a pattern for phone number validation
                                                                    className="form-control"
                                                                    id="mobileNumber"
                                                                    placeholder="+91 9876543210"
                                                                    name="mobileNumber"
                                                                    value={contact.mobileNumber}
                                                                    onChange={handleChange}
                                                                    aria-describedby="basic-addon4"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button
                                                    className='modal-join-btn w-100  py-[0] px-[15px] gap-[5px] rounded-[40px]  font-semibold text-[#1a2b1a] bg-[#adf075] border-[1.5px] border-solid border-[#1a2b1a] mt-[10px] flex justify-center items-center'
                                                    onClick={handleReplaceContent}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <>
                                                            <div className="spinner-border spinner-border-sm text-dark mr-2" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                            Loading
                                                        </>
                                                    ) : (
                                                        "Join our waiting list"
                                                    )}
                                                </button>
                                                <p className='p-text-3 font-medium text-center mt-[10px] text-[#454a45]'>
                                                    *No credit card required
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinModal;
