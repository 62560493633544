import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy'>
      <div className='privacy-body'>
        <div className='p-head'>
          <h2 className='h-text-1'>Privacy Policy</h2>
          <p className='p-text-1'>Effective date: 11th of September, 2024</p>
        </div>
        <div className='p-body'>
          <div className='p-content'>
            <h6 className='h-text-2'>1. Introduction</h6>
            <p className='p-text-2'>
              Auto Nexxus ("we," "our," "us") values the privacy of our users and is committed to protecting their personal information. <br className="break"/>
              This Privacy Policy outlines how we collect, use, store, and disclose personal information.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>2. Information We Collect</h6>
            <p className='p-text-2'>
              Personal Information: Includes name, email, phone number, and address details. <br className="break"/>
              Service-related information: Job card details, vehicle information, service history, and parts used. <br className="break"/>
              Financial Information: Payment methods, transaction history, and invoicing details. <br className="break"/>
              Usage Data: Includes cookies, IP address, browser type, and pages visited. <br className="break"/>
              Communications: Messages, feedback, and other intractions via support channels.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>3. How We Use Your Information</h6>
            <p className='p-text-2'>
              We use the information we collect to: <br className="break"/>
              Provide Services: Operate the Auto Nexusx platform, manage user accounts, and process transactions. <br className="break"/>
              Personalize Experiences: Tailor features, recommendations, and service information based on user preferences. <br className="break"/>
              Improve the Platform: Analyze performance, monitor trends, and develop new functionalities. <br className="break"/>
              Communicate with Users: Notify users of updates, provide customer support, and send relevant communications. <br className="break"/>
              Compliance: Ensure we comply with legal, financial, and data protection obligations.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>4. Cookies and Tracking Technologies</h6>
            <p className='p-text-2'>
              We use cookies and similar tracking technologies to enhance the user experience. These may include: <br className="break"/>
              Necessary Cookies: For essential functions such as logging in. <br className="break"/>
              Performance Cookies: To understand user interaction and improve the platform. <br className="break"/>
              Marketing Cookies: To serve relevant ads and offers if applicable.<br className="break"/>
              Users can manage cookie preferences via their browser settings.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>5. Data Sharing and Disclosure</h6>
            <p className='p-text-2'>
              We do not share personal information except as follows: <br className="break"/>
              Third-party Service Providers: To facilitate operations (e.g. payment processing, cloud hosting).<br className="break"/>
              Legal Requirements: To comply with legal obligations or protect the rights and safety of users.<br className="break"/>
              Business Transfers: In the event of a merger or sale of assets, user data may be transferred to the new entity.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>6. International Data Transfers</h6>
            <p className='p-text-2'>
              We may transfer personal information to other countries in which we or our third-party service providers operate. We <br className="break"/>
              ensure that any such transfers comply with applicable data protection laws.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>7. Your Rights</h6>
            <p className='p-text-2'>
              Users have the right to: <br className="break"/>
              Access: Request a copy of the personal data we hold.<br className="break"/>
              Correction: Rectify any inaccurate or incomplete information.<br className="break"/>
              Deletion: Request the deletion of data, where legally permissible. <br className="break"/>
              Restriction: Limit the processing of their data in certain circumstances. <br className="break"/>
              Portability: Receive their data in a commonly used format.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>8. Data Security</h6>
            <p className='p-text-2'>
              We take data security seriously and employ appropriate technical and organizational measures to safeguard user data. <br className="break"/>
              However, users must also protect their account credentials and notify us if they suspect unauthorized activity.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>9. Data Retention</h6>
            <p className='p-text-2'>
              We retain personal information for as long as necessary to provide services or as required by law. Users may request the <br className="break"/>
              deletion of their data subject to legal obligations.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>10. Children's Privacy</h6>
            <p className='p-text-2'>
              Our platform is not intended for children under 16, and we do not knowingly collect data from them. If we become aware<br className="break"/>
              of such data, we will take steps to delete it.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>11. Changes to the Privacy Policy</h6>
            <p className='p-text-2'>
              We may update this Privacy Policy as needed. Users will be notified of material changes via email or platform alerts.
            </p>
          </div>
          <div className='p-content'>
            <h6 className='h-text-2'>12. Contact Information</h6>
            <p className='p-text-2'>
              For any questions or concerns about this Privacy Policy, please contact us at:
            </p>
            <p className='p-text-2 mt-2'>
              If you have questions about these Terms of Service, our Privacy Policy, our Refund Policy, our Charging Model, our Business <br className="break"/>
              Model, our Technical Issues, or our Inaccurate Data, please contact us at <a href='mailto: admin@autonexxus.app'>admin@autonexxus.app </a>,<br className="break"/>
              <a href='mailto: business@autonexxus.app'>business@autonexxus.app </a>, <a href='mailto: marketing@autonexxus.app'>marketing@autonexxus.app </a>, <a href='mailto: engineering@autonexxus.app'>engineering@autonexxus.app </a>, <a href='mailto: design@autonexxus.app'>design@autonexxus.app</a>, based on <br className="break"/>
              your communication needs. <br className="break"/>
            </p>
            <p className='p-text-2 mt-2'>
              This Agreement will become effective as of the date of your first use of the Services and remain in effect until terminated<br className="break"/>
              by either you or Auto Nexxus. You may terminate this Agreement at any time by ceasing to use the Services. You may also <br className="break"/>
              Services at any time without cause, and Auto Nexxus may suspend your access to the Services at any  <br className="break"/>
              time without notice or liability to you. Your rights to use the Services will cease immediately upon termination of this<br className="break"/>
              Agreement. Please contact <a href='mailto: engineering@autonexxus.app'>engineering@autonexxus.app </a> if you wish to terminate your account. <br className="break"/>
              It is intended that all provisions of this Agreement that, by their nature, should survive termination shall survive termination, <br className="break"/>
              including, without limitation, ownership provisions, warranty disclaimers, indemnities, and liability limitations.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
