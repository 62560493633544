import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import dimg1 from "../Assets/Images/Desktop/Financial Reporting.webp"
import dimg2 from "../Assets/Images/Desktop/Invoicing.webp"
import dimg3 from "../Assets/Images/Desktop/Profitability Analysis.webp"
import dimg4 from "../Assets/Images/Desktop/tc-car.webp"


import mimg1 from "../Assets/Images/Mobile/Financial Reporting - Mobile@2x.webp"
import mimg2 from "../Assets/Images/Mobile/Invoicing - Mobile@2x.webp"
import mimg3 from "../Assets/Images/Mobile/Technician Profiles - Mobile@2x.webp"
import mimg4 from "../Assets/Images/Mobile/Profitability Analysis - Mobile@2x.webp"


import invoicing from "../Assets/Images/webp/Invoicing.webp"


const HomeSection6 = () => {
    return (
        <div className='h-section-6 w-100 flex justify-center items-center bg-[#eef2f3]' >
            <div className='h-sec6-banner lg:w-[80%] w-[90%] py-[40px] px-[0] pb-[56px]  pt-[56px]'>
                <div className='sec6-top flex flex-col justify-center items-center '>
                    <div className='div-management  py-[5px] px-[10px] '>
                        <p className='mb-0 text-[14px] font-semibold text-[#537e30]' style={{ letterSpacing: "1px" }}>FINANCE & TECHNICIAN MANAGEMENT</p>
                    </div>
                    <h2 className='h-text-1  font-bold text-center text-[#173300] mt-[8px]' >Streamline Financials and Optimize <br className='break' />
                        Technician Performance
                    </h2>
                    <p className='p-text-1 text-[18px] font-normal text-center text-[#454A45] mt-[12px]'>Auto Nexxus GMS streamlines finance and technician management with unified  <br className='break' />budgeting, invoicing, and tracking.</p>
                </div>
                {/* <div className='row g-3 mt-4'>
                    <div className='col-lg-6 col-md-12 col-12  ' >
                        <div className='card border-0 bg-transparent w-100 h-100 '>
                            <LazyLoadImage src={moneyimg} alt='money' className='w-100 h-100 relative' />
                            <h5 className='h-text-2  font-bold text-[#080c08] absolute top-[26px] left-[26px]'>Real-Time Financial <br />Tracking & Reporting</h5>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-12'>
                        <div className='card sec6-bg-2 h-100 border-[1px] border-solid border-[#1A2B1A33] flex justify-center items-center py-[20px] px-[0]'>
                            <div className='col-lg-8 col-md-8 col-10'>
                                <div className='sec6-card-content h-100 flex flex-col items-center justify-between gap-[10px]'>
                                    <h5 className='h-text-2  font-bold text-[#080c08] text-center'>Accuracy Invoicing & <br />Payment Processing</h5>
                                    <div className='sec6-card-design w-100 rounded-[20px] bg-[#ADF075]'>
                                        <div className='black-bg w-100 bg-[#1A2B1A] rounded-[20px] flex flex-col justify-between items-center py-[20px] px-[0] gap-[10px]'>
                                            <LazyLoadImage src={billcheck} alt='billcheck' />
                                            <div>
                                                <h4 className='h-text-3  font-bold text-[#eef2f3] mb-[5px]'>₹47,100.<span className='s-text-1 font-light'>00</span></h4>
                                                <p className='p-text-2  font-normal text-[#c2c6c7] text-center'>#INV4257-07-011</p>
                                            </div>
                                        </div>
                                        <div className='card-bottom flex justify-between items-center py-[10px] px-[20px]'>
                                            <p className='p-text-3 mb-0  font-medium text-[#080c08]'>15:36 | 03 JULY 2024</p>
                                            <button className='btn-paid p-text-3 bg-transparent py-[2px] px-[10px] font-bold text-[#1a2b1a] border-[1.5px] border-solid border-[#1a2b1a] rounded-[20px] mb-0' style={{ letterSpacing: "2px" }}>PAID</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col-12'>                   
                        <div className='card bg-transparent h-100 border-0 tab-hide mobile-hide'>
                            <LazyLoadImage src={car} alt='image' className='w-100 h-100 relative' />
                            <h1 className='absolute'>jksdjfks</h1>
                        </div>
                        <div className='card bg-transparent h-100 border-0 desktop-hide'>
                            <LazyLoadImage src={carimg} alt='image' className='w-100 h-100 relative' />
                            <div className='card-right-car py-[40px] px-[15px] absolute '>
                                <h4 className='h-text-2  font-bold text-[#080c08]'>Detailed Technician Profiles & <br className='break' />
                                    Role-Based Task Assignment
                                </h4>
                                <p className='p-text-4  font-medium text-[#454a45]'>Optimize your workforce with detailed technician profiles and efficient task allocation.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-12 col-12'>
                        <div className='card sec6-bg-1 sec6-bg-4 w-100 border-0  relative'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12 relative'>
                                    <div className='card border-0 bg-transparent'>
                                        <LazyLoadImage src={pimg} alt='money' className='relative' />
                                        <h5 className='h-text-2  font-bold text-[#080c08] absolute top-[18px] left-[20px]'>Profitability <br />Analysis</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='row g-3 mt-4  mobile-hide xs-hide'>
                    <div className='col-lg-6 col-md-6 col-6' style={{marginTop:"20px"}}>
                        <LazyLoadImage src={dimg1} alt='img' className='img-fluid' /> 
                    </div>
                    <div className='col-lg-6 col-md-6 col-6' style={{marginTop:"20px"}}>
                        <LazyLoadImage src={dimg2} alt='img' className='img-fluid' />
                    </div>
                    <div className='col-lg-7 col-md-7 col-7' style={{marginTop:"20px"}}>
                        <LazyLoadImage src={dimg4} alt='img' className='img-fluid '/>
                    </div>
                    <div className='col-lg-5 col-md-5 col-5' style={{marginTop:"20px"}}>
                        <LazyLoadImage src={dimg3} alt='img' className='img-fluid '  />
                    </div>
                </div>

                <div className='row desktop-hide tab-hide mobile-row ' style={{marginTop:"8px"}} >
                    <div className='col-lg-12 col-md-12 col-12 '>
                        <LazyLoadImage src={mimg1} alt='img' className='img-fluid' style={{ width: "100%" }} />
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <LazyLoadImage src={mimg2} alt='img' className='img-fluid' style={{width:"100%", marginTop:"12px"}} />
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <LazyLoadImage src={mimg3} alt='img' className='img-fluid' style={{width:"100%",marginTop:"12px"}}/>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <LazyLoadImage src={mimg4} alt='img' className='img-fluid' style={{width:"100%",marginTop:"12px"}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSection6
