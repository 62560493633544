import React from 'react'
import calender from "../Assets/Images/calender.svg"
import likeimg from "../Assets/Images/likeimg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import btnarrow from "../Assets/Images/r-arrow-round.svg"


const Contact = () => {
    return (
        <div className='contact w-100 flex flex-col justify-center items-center bg-[#eef2f3]'>
            <section className='contact-sec-1 w-[90%] pt-[120px] flex flex-col justify-center items-center gap-[15px] '>
                <div className='div-contact py-[5px] px-[10px] '>
                    <p className='mb-[0px] text-[14px] font-bold text-[#537e30]' style={{ letterSpacing: "1px" }}>CONTACT US</p>
                </div>
                <h1 className='h-text-1  font-semibold text-center text-[#1a2b1a]'>We're here to help </h1>
                <p className='p-text-1 font-medium text-center text-[#1a2b1a]'>Get in touch with us for personalized support, expert advice, and<br className='break' />
                    seamless solutions to elevate your garage management.</p>
            </section>
            <section className='contact-sec-2 w-[90%] pb-[50px]'>
                <div className='row g-3 mt-2 '>
                    <div className='col-lg-6 col-md-6 col-12 '>
                        <div className='card w-100 h-100 flex flex-col items-start justify-between gap-[10px] py-[20px] px-[30px] bg-[#ebeff0] rounded-[25px] pr-[100px] '>
                            <LazyLoadImage src={calender} alt='calender' className='calender-img w-[30px] h-auto' />
                            <h2 className='h-text-2  font-bold text-[#080c08] mb-[0]'>Schedule a Demo</h2>
                            <p className='p-text-2 font-medium text-[#454a45] mb-1'>Book a personalized demo with our experts and <br className='break' />discover how our platform can transform your <br className='break' />garage operations.</p>
                            <button className='btn-schedule py-[5px] px-[10px] rounded-[20px] border-[1.5px] border-solid border-[#080c08] text-[14px] font-semibold flex justify-center items-center gap-[5px]'>
                                Schedule now
                                <LazyLoadImage src={btnarrow} alt='arrow' />
                            </button>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='card  w-100 h-100 flex flex-col items-start justify-between gap-[10px] py-[20px] px-[30px] bg-[#ebeff0] rounded-[25px] pr-[100px]'>
                            <LazyLoadImage src={likeimg} alt='calender' className='calender-img w-[30px] h-auto' />
                            <h2 className='h-text-2  font-bold text-[#080c08] mb-[0]'>Send us a Message</h2>
                            <p className='p-text-2  font-medium text-[#454a45] mb-1'>We're just a message away. Whether it's a product <br className='break' />inquiry, feedback, or support request. Our team is <br className='break' />ready to assist you.</p>
                            <button className='btn-schedule py-[5px] px-[10px] rounded-[20px] border-[1.5px] border-solid border-[#080c08] text-[14px] font-semibold flex justify-center items-center gap-[5px]'>
                                Get in touch
                                <LazyLoadImage src={btnarrow} alt='arrow' />
                            </button>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Contact
