import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import greencircle from "../Assets/Images/greencircle.svg";
import Marquee from "react-fast-marquee";
import btnarrow from "../Assets/Images/r-arrow-round.svg";
import CustomCursor from './CursorDemo';
const HomeSection7 = forwardRef((props, ref) => {

  const [showCursor, setShowCursor] = useState(false);
  return (
    <div ref={ref} className='h-section-7 w-100 flex flex-col justify-center items-center bg-[#080c08]' id='section2'>
      <div className='h-sec7-banner pt-[40px] px-[10px] flex flex-col items-center justify-center gap-[10px] '>
        <div className='access-div py-[5px] px-[10px] '>
          <p className='mb-0 font-semibold text-[#adf075]' style={{ letterSpacing: "1px" }}>GET ACCESS</p>
        </div>
        <h2 className='h-text-1  font-bold text-center text-[#eef2f3]'>Need Expert Advice? Let’s Talk!</h2>
        <p className='p-text-1  font-medium text-center text-[#c2c6c7]'>We're here to help you transform your garage operations. Whether you have questions or want a <br className='break' />personalized demo, our experts are ready to assist you.</p>
      </div>
      <CustomCursor showCursor={showCursor} />
      <div className='morque-div w-100 py-[30px] px-[0]'
      >
        <Marquee >
          <div className='marquee-item relative pb-[20px]'>
            <p className='p-text-2 text-[100px] font-bold text-[#ffff]  mb-0 flex justify-center items-center gap-[50px] ml-[50px]   transition duration-200 ease-in-out hover:text-[#98D864] hover-target'
              onMouseEnter={() => setShowCursor(true)}
              onMouseLeave={() => setShowCursor(false)}
              data-bs-toggle="modal" data-bs-target="#joinModal"
            >
              <LazyLoadImage src={greencircle} alt='green' className='green-circle' />
              Get Free Demo
            </p>
          </div>
          <div className='marquee-item relative pb-[20px]'>
            <p className='p-text-2 text-[100px] font-bold text-[#ffff] mb-0 flex justify-center items-center  gap-[50px] ml-[50px]  transition duration-200 ease-in-out hover:text-[#98D864] hover-target'
              onMouseEnter={() => setShowCursor(true)}
              onMouseLeave={() => setShowCursor(false)}
              data-bs-toggle="modal" data-bs-target="#joinModal"
            >
              <LazyLoadImage src={greencircle} alt='green' className='green-circle' />
              Get Free Demo
            </p>
          </div>
          <div className='marquee-item relative pb-[20px]'>
            <p className='p-text-2 text-[100px] font-bold text-[#ffff] mb-0 flex justify-center items-center  gap-[50px] ml-[50px]  transition duration-200 ease-in-out hover:text-[#98D864] hover-target'
              onMouseEnter={() => setShowCursor(true)}
              onMouseLeave={() => setShowCursor(false)}
              data-bs-toggle="modal" data-bs-target="#joinModal"
            >
              <LazyLoadImage src={greencircle} alt='green' className='green-circle' />
              Get Free Demo
            </p>
          </div>
        </Marquee>
      </div>
    </div>
  );
});

export default HomeSection7;
