import React from 'react';
import banner from "../Assets/Images/a-banner.webp";

const About = () => {


  return (
    <div className='about w-100 flex flex-col justify-center items-center bg-[#080c08]' id="section3" >
      <section className='about-sec-1 w-[90%] pt-[180px] flex flex-col justify-center items-center gap-[15px]'>
      <h1 className="h-text-1 font-semibold text-center text-[#eef2f3]" >
                    Driving the Future of <br className='break' />Garage Management
                </h1>

        <p className='p-text-1  font-medium text-center text-[#c2c6c7] mt-[28px]'>Transforming every garage into a hub of <br className='break' />innovation and efficiency.</p>
        <img src={banner} alt='banner' className='about-img img-fluid w-100 mt-[80px]' />
      </section>
      <section className='about-sec-2 w-[90%] flex flex-col items-center justify-center gap-[10px] py-[30px] px-[0] mt-[80px] pb-[80px]'>
        <div className='story-div py-[5px] px-[10px] '>
          <p className='mb-0 font-semibold text-[#adf075]' style={{ letterSpacing: "1px" }}>OUR STORY</p>
        </div>
        <h2 className='h-text-2 font-semibold text-center text-[#eef2f3]' style={{letterSpacing:"0.5px"}}>Auto Nexxus was born out of a profound <br className='break' />
          understanding of the challenges faced by garage <br className='break' />
          owners and managers in the automative industry.</h2>
        <p className='p-text-2  font-medium text-center text-[#c2c6c7] mt-[40px]'>Traditional garage management systems were often cumberstone, inefficient, and outdated. Seeing the need for a more effective solution, a
          team of seasoned industry experts with years of experience in the automative sector came together to create a technology-driven platform
          designed to streamline operations, improve customer satisfaction, and drive business growth.</p>
        <div className='mt-[80px]'>
        <div className='row g-2 '>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='card card-1 w-100 bg-transparent p-[10px] border-r-[1px] border-solid border-[#adf0752e] rounded-[0px] flex flex-column gap-[8px]' >
              <h2 className='h-text-3 font-bold text-start text-[#eef2f3]'>Mission</h2>
              <p className='p-text-3  font-medium text-start text-[#c2c6c7]' >To transform the garage industry through a
                comprehensive platform designed to streamline operations, enhance customer experience,
                and deliver actionable insights. Our solution empowers garage owners to make data-driven decisions, optimize resource allocation, and improve overall efficiency.</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='card  w-100 bg-transparent p-[10px] lg:pl-[20px] md:pl-[0px] flex flex-column gap-[8px]'>
              <h2 className='h-text-3 font-bold text-start text-[#eef2f3]'>Vision</h2>
              <p className='p-text-3  font-medium text-start text-[#c2c6c7]'>We envision a future where the automotive industry operates seamlessly, with data-driven insights
                informing every decision. A central nexus, connecting all stakeholders, manufacturers, dealerships, garages, and consumers.
                To create a more efficient, transparent, and sustainable automotive ecosystem.</p>
            </div>
          </div>
        </div>
       </div>
       
      </section>
    </div>
  );
}

export default About;
