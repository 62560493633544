import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation } from 'react-router-dom';
import btnarrow from "../../Assets/Images/r-arrow-round.svg";
import wbtnarrow from "../../Assets/Images/w-btnarrow.svg";
import menu from "../../Assets/Images/menu.svg";
import logoblack from "../../Assets/Images/logoblack.svg";
import logowhite from "../../Assets/Images/logowhite.svg";
import closemenu from "../../Assets/Images/closemenu.svg";
import { isDOMComponent } from 'react-dom/test-utils';

const TopNavbar = ({ triggerSectionId }) => {
    const [isDark, setIsDark] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const checkSectionsInView = () => {
        const navbar = document.getElementById('navbar');
        const navbarRect = navbar ? navbar.getBoundingClientRect() : { bottom: 0 };

        const isAnySectionInView = triggerSectionId.some(sectionId => {
            const triggerSection = document.getElementById(sectionId);
            if (triggerSection) {
                const sectionRect = triggerSection.getBoundingClientRect();
                return navbarRect.bottom >= sectionRect.top && navbarRect.bottom <= sectionRect.bottom;
            }
            return false;
        });

        setIsDark(isAnySectionInView);
    };

    const handleScroll = () => {
        checkSectionsInView();

        triggerSectionId.forEach(id => {
            const section = document.getElementById(id);
            if (section) {
                const sectionTop = section.getBoundingClientRect().top;
                if (sectionTop < window.innerHeight && sectionTop > 0) {
                    section.classList.add('highlight');
                } else {
                    section.classList.remove('highlight');
                }
            }
        });

        const section4 = document.getElementById('section4');
        if (section4) {
            const sectionBottom = section4.getBoundingClientRect().bottom;
            if (sectionBottom <= window.innerHeight) {
                section4.classList.add('shrink');
            } else {
                section4.classList.remove('shrink');
            }
        }
    };

    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggerSectionId]);

    return (
        <div className="top-navbar" id="navbar">
            <div className={`navbar-linear-bg ${isOpen ? "no-radius" : ""}`}>
                <nav className={`${isDark ? 'navbar-dark' : 'navbar-light'} navbar   ${isOpen ? "no-radius" : ""}`}>
                    <div className='nav-des'>
                        <div className='nav-start'>
                            <Link to="/" reloadDocument>
                                {
                                    isDark ? <LazyLoadImage src={logowhite} alt='logo' className='logo' /> :
                                        <LazyLoadImage src={logoblack} alt='logo' className='logo' />
                                }
                            </Link>
                        </div>
                        <div className='nav-center tab-hide mobile-hide'>
                            <Link to="/about-us" reloadDocument
                                className={`nav-link ${activeLink === '/about-us' ? 'active' : ''}`}
                                onClick={() => setActiveLink('/about-us')}>
                                <h6 className={`${isDark ? "h-text-2-dark" : "h-text-2-lite"} h-text-2`}>About us</h6>
                            </Link>
                            <Link to="/contact" reloadDocument
                                className={`nav-link ${activeLink === '/contact' ? 'active' : ''}`}
                                onClick={() => setActiveLink('/contact')}>
                                <h6 className={`${isDark ? "h-text-2-dark" : "h-text-2-lite"} h-text-2`}>Contact</h6>
                            </Link>
                        </div>
                        <div className='nav-end'>                         
                            <button className={`${isDark ? "btn-join-dark" : "btn-join-light"} btn-join tab-hide mobile-hide`} data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                {
                                    isDark ? <LazyLoadImage src={btnarrow} alt='arrow' /> :
                                        <LazyLoadImage src={wbtnarrow} alt='arrow' />
                                }

                            </button>
                            <button className={`${isDark ? "btn-toggle-mobile-dark" : "btn-toggle-mobile-light"} desktop-hide accordion-button`} onClick={toggleAccordion} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <LazyLoadImage src={isOpen ? closemenu : menu} alt='menu' className={`${isDark ? "menu-icon-dark" : "menu-icon-light"}`} />
                            </button>
                        </div>
                    </div>

                </nav>
            </div>


            <div className=' desktop-hide  '>
                {isOpen && (
                    <div className='mobile-menu-bg'>
                        <div className={` mobile-menu  ${isDark ? "mobile-menu-dark" : "mobile-menu-lite"} ${isOpen ? 'slide-in' : 'slide-out'}`}>
                            <div className='mobile-nav-start'>
                                <p className={`mobile-p ${isDark ?
                                    "mobile-p-dark" : "mobile-p-lite"
                                    }`}>
                                    Step into the future of Garage Management  <br />with Auto Nexus
                                </p>
                                <Link to="/about-us" reloadDocument className='nav-link-mobile' onClick={toggleAccordion}>
                                    <h6 className={`${isDark ? "h-text-2-dark" : "h-text-2-lite"} h-text-2`}>About us</h6>
                                </Link>
                                <Link to="/contact" reloadDocument className='nav-link-mobile' onClick={toggleAccordion}>
                                    <h6 className={`${isDark ? "h-text-2-dark" : "h-text-2-lite"} h-text-2`}>Contact</h6>
                                </Link>
                            </div>

                            <button onClick={toggleAccordion} className={`${isDark ? "btn-join-dark" : "btn-join-light"} btn-join `} data-bs-toggle="modal" data-bs-target="#joinModal">Join our waiting list
                                {
                                    isDark ? <LazyLoadImage src={btnarrow} alt='arrow' /> :
                                        <LazyLoadImage src={wbtnarrow} alt='arrow' />
                                }</button>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default TopNavbar;
