import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cloudicon from "../Assets/Images/cloudicon.svg";
import efforticon from "../Assets/Images/effortlessicon.svg";
import wifi from "../Assets/Images/wifi-icon.svg";
import tallyicon from "../Assets/Images/tallyicon.svg";
import solidicon from "../Assets/Images/solidicon.svg";
import realtimeicon from "../Assets/Images/realtimeicon.svg";
import garageicon from "../Assets/Images/multi-icon.svg";
import supporticon from "../Assets/Images/supporticon.svg";

const cardData = [
    { id: 1, icon: cloudicon, title: 'Cloud Integration', description: 'With Auto Nexxus, everything is saved in the cloud enjoy seamless access, automatic backups.' },
    { id: 2, icon: efforticon, title: 'Effortless Data Migration', description: 'Switch to Auto Nexxus without a hitch. Migrate your data seamlessly and start managing.' },
    { id: 3, icon: wifi, title: 'Offline Functionality', description: 'Keep your operations running smoothly. even without an internet connection.' },
    { id: 4, icon: tallyicon, title: 'Tally Integration', description: 'Sync your Tally database with Auto Nexxus for easy data importing. no manual entry.' },
    { id: 5, icon: solidicon, title: 'Rock-Solid Security', description: 'Rest easy knowing your data is protected by cutting-edge security and encryption.' },
    { id: 6, icon: realtimeicon, title: 'Real-Time Insights', description: 'Stay ahead with live, data-driven analytics, actionable insights to drive your garage forward.' },
    { id: 7, icon: garageicon, title: 'Multi-Garage Management', description: 'Manage customers across multiple locations with consistent service and data accuracy.' },
    { id: 8, icon: supporticon, title: '24/7 Dedicated Support', description: 'Never face a challange alone. our support team is here for you anytime you need help.' },
];


const HomeSection3 = forwardRef(({ textColor, bColor, btcolor, cTcolor, borderCcolor }, ref) => {


    return (
        <div  className='h-section-3 w-100 h-100 flex justify-center items-center '   >
            <div className='h-sec3-banner lg:w-[80%] md:w-[80%] w-[90%]  pt-[140px] px-[0px]'>
                <div className='sec3-top '>
                    <div className='flex flex-col justify-center items-center ' >
                        <div
                           
                            className='div-revolutionary  py-[5px] px-[10px]  rounded-[40px] flex justify-center items-center'
                           
                        >
                            <p className='mb-[0px] div-r-p font-semibold' style={{ letterSpacing: "1px", color: btcolor }}>REVOLUTIONARY MANAGEMENT</p>
                        </div>
                        <div id='div-revolutionary'>
                        <div id='section1' className='flex flex-col justify-center items-center gap-[10px] pt-[10px] pb-[80px]'>
                            <h2 className='h-text-1  font-bold text-[#EEF2F3] text-center' style={{ color: textColor }}>
                                The Next Generation of Garage <br className='break' />
                                Management Software
                            </h2>
                            <p className='p-text-1 text-[18px] font-medium  text-center' style={{ color: textColor }}>
                                Step into the future with Auto Nexxus, replace outdated, complex tools with advanced, intuitive <br className='break' />
                                software designed for seamless integration.
                            </p>
                            <div
                                className=" row mt-4 "
                            >
                                {cardData.map((card, index) => (
                                    <div
                                        key={card.id}
                                        className={`col-lg-3 col-md-6 col-6 p-0 ${index < 4 ? 'no-border-top' : 'no-border-bottom'}`}
                                    >
                                        <div className='card h-100 bg-transparent rounded-0 lg:px-[20px] lg:pt-[24px] lg:pb-[40px] md:px-[10px] md:pt-[12px] sm:px-[5px] sm:pt-[6px] sm:pb-[10px] ' style={{ height: "248px" }}>
                                            <div className='card-body flex flex-col items-start'>
                                                <img src={card.icon} alt={card.title} className='card-img-hs3' />
                                                <h6 className='h-text-2  font-semibold mb-[0px] mt-[12px]' style={{ color: textColor, lineHeight: "148%" }}>{card.title}</h6>
                                                <p className='p-text-2 text-[14px] font-normal mb-[0px] mt-[12px]' style={{ color: cTcolor, lineHeight: "148%", letterSpacing: "1%" }}>{card.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        </div>
                       

                    </div>
                </div>
            </div>
        </div>
    );
});

export default HomeSection3;
