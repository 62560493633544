import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import btnarrow from "../Assets/Images/r-arrow-round.svg";
import bannerImg from "../Assets/Images/Bbannerimg.svg";

const HBanner = ({ textColor }) => {
    return (
        <div id="h-banner" className="h-banner w-100 flex justify-center items-center pt-[80px]">
            <div className='row g-3 m-0 w-100 '>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div className='b-right h-100 w-100 flex flex-col items-start justify-center gap-[15px] lg:pr-[10px] lg:pt-[10px] lg:pl-[80px] lg:pb-[20px] pr-[16px]  '>
                        <h1 className='h-text-1 font-semibold ' style={{ color: textColor, letterSpacing: "-2%", lineHeight: "100%" }}>
                            <span className='span-element'>Streamline, </span> <br />
                            <span className='span-element'> Optimize, </span>
                            <span className='span-element'> and </span><br />
                            <span className='span-element'> Grow</span>
                            <span className='span-element'> Your </span>
                            <span className='span-element'>Garage </span>
                        </h1>
                        <p className='p-text-1 font-medium ' style={{ color: textColor, }}>
                            <span className='span-element-1'> Stay </span>
                            <span className='span-element-1'> ahead </span>
                            <span className='span-element-1'> of </span>
                            <span className='span-element-1'> the</span>
                            <span className='span-element-1'> competition </span>
                            <span className='span-element-1'> with </span>
                            <span className='span-element-1'> advanced </span>
                            <span className='span-element-1'> tools </span>
                            <span className='span-element-1'> for </span> <br className='break' />
                            <span className='span-element-1'> smarted, </span>
                            <span className='span-element-1'> more </span>
                            <span className='span-element-1'> efficient </span>
                            <span className='span-element-1'> garage </span>
                            <span className='span-element-1'> management </span>
                        </p>
                        <button
                            className='btn-banner-join py-[10px] px-[24px] flex justify-between items-center gap-[8px] rounded-[40px] font-semibold border-[1.5px] border-solid  text-[#1A2B1A] bg-[#ADF075]'
                            style={{ borderColor: textColor }}
                            data-bs-toggle="modal"
                            data-bs-target="#joinModal"
                        > 
                            Join our waiting list
                            <LazyLoadImage src={btnarrow} alt='arrow' className='w-[26px]' />
                        </button>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12'>
                    <div className='b-left w-100 lg:flex lg:justify-end lg:items-end md:items-center'>
                        <LazyLoadImage src={bannerImg} alt='bannerimg' className='banner-img lg:w-[90%] md:w-[100%] h-auto' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HBanner;
