import React from 'react'

const DataUsage = () => {
    return (
        <div className='data-usage privacy-policy '>
            <div className='privacy-body'>
                <div className='p-head'>
                    <h2 className='h-text-1'>Data Usage Policy</h2>
                    <p className='p-text-1'>Effective date: 11th of September, 2024</p>
                </div>
                <div className='p-body'>
                    <div className='p-content'>
                        <h6 className='h-text-2'>1. Introduction</h6>
                        <p className='p-text-2'>
                            Auto Nexusx ("we", "our", "us") is committed to ensuring the proper use and management of user data. This Data Usage <br />
                            Policy explains how we collect, process, and use data from our users to enhance their experience with the platform, ensure <br />
                            operational efficiency, and comply with legal requirements.
                        </p>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>2. Data Collection</h6>
                        <p className='p-text-2'>
                            We collect the following types of data:
                        </p>
                        <ul>
                            <li>User Information: Name, email address, phone number, company details, and account login credentials.</li>
                            <li>Vehicle Information: Vehicle registration numbers, model/make, service history, and job card details.</li>
                            <li>Service Data: Information related to services provided, such as job card details, parts used, and service status. </li>
                            <li>Financial Data: Invoice history, payment information, profit/loss statements, and transaction records. </li>
                            <li>Device Information: IP addresses, browser type, device type, and other device-specific information. </li>
                            <li>Usage Data: Interaction with the platform, page views, and features accessed for performance tracking.</li>
                        </ul>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>3. How We Use Your Data</h6>
                        <p className='p-text-2'>
                            We use the collected data for:<br />
                        </p>
                        <ul>
                            <li>Operational Purposes: Managing garage operations, job cards, invoices, and payments. </li>
                            <li>Service Improvement: Analyzing usage patterns to optimize platform performance and introduce new features. </li>
                            <li>Customer Support: Addressing inquiries, troubleshooting issues, and improving customer service. </li>
                            <li>Marketing: With consent, we may use data to offer promotional materials, updates, and product news. </li>
                            <li>Legal Compliance: Ensuring adherence to legal obligations, such as tax regulations or court orders. </li>
                        </ul>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>4. Data Sharing</h6>
                        <p className='p-text-2'>
                            We do not sell or share user data with third parties except under the following conditions:
                        </p>
                        <ul>
                            <li>Service Providers: We may share data with third-party vendors who assist in platform functionality (e.g., cloud hosting, payment processing). </li>
                            <li>Legal Obligations: If required by law, we will disclose user data in response to legal requests such as subpoenas or court orders. </li>
                            <li>Business Transfers: In case of a merger or acquisition, user data may be transferred to the acquiring entity. </li>
                        </ul>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>5. Data Retention</h6>
                        <p className='p-text-2'>
                            We retain user data for as long as necessary to fulfill the purposes outlined in this policy or as required by law. Once data is <br />
                            no longer needed, we securely delete or anonymize it.
                        </p>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>6. User Rights</h6>
                        <p className='p-text-2'>
                            Users have the following rights regarding their data:
                        </p>
                        <ul>
                            <li>Access: Users can request a copy of the personal data we hold about them. </li>
                            <li>Correction: Users can correct inaccurate or incomplete data. </li>
                            <li>Deletion: Users can request the deletion of their data, subject to legal requirements. </li>
                            <li>Objection: Users can object to certain data processing activities, such as marketing communications. </li>
                        </ul>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>7. Security Measures</h6>
                        <p className='p-text-2'>
                            We implement industry-standard security measures to protect user data, including encryption, firewalls, and regular <br />
                            audits. Despite these precautions, no system is 100% secure, and users should also take steps to protect their accounts.<br />
                        </p>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>8. Changes to the Data Usage Policy</h6>
                        <p className='p-text-2'>
                            We may update this Data Usage Policy periodically. Users will be notified of any significant changes through email or <br />
                            platform alerts.
                        </p>
                    </div>
                    <div className='p-content'>
                        <h6 className='h-text-2'>9. Contact Information</h6>
                        <p className='p-text-2'>
                            For any questions or concerns about this Privacy Policy, please contact us at:
                        </p>
                        <p className='p-text-2 mt-2'>
                            If you have questions about these Terms of Service, our Privacy Policy, our Refund Policy, our Charging Model, our Business <br />
                            Model, our Technical Issues, or our Inaccurate Data, please contact us at <a href='mailto: admin@autonexxus.app'>admin@autonexxus.app </a>,<br />
                            <a href='mailto: business@autonexxus.app'>business@autonexxus.app </a>, <a href='mailto: marketing@autonexxus.app'>marketing@autonexxus.app </a>, <a href='mailto: engineering@autonexxus.app'>engineering@autonexxus.app </a>, <a href='mailto: design@autonexxus.app'>design@autonexxus.app</a>, based on <br />
                            your communication needs. <br />
                        </p>
                        <p className='p-text-2 mt-2'>
                            This Agreement will become effective as of the date of your first use of the Services and remain in effect until terminated<br />
                            by either you or Auto Nexxus. You may terminate this Agreement at any time by ceasing to use the Services. You may also <br />
                           cease using the  Services at any time without cause, and Auto Nexxus may suspend your access to the Services at any  <br />
                            time without notice or liability to you. Your rights to use the Services will cease immediately upon termination of this<br />
                            Agreement. Please contact <a href='mailto: engineering@autonexxus.app'>engineering@autonexxus.app </a> if you wish to terminate your account. <br />
                            It is intended that all provisions of this Agreement that, by their nature, should survive termination shall survive termination, <br />
                            including, without limitation, ownership provisions, warranty disclaimers, indemnities, and liability limitations.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DataUsage
